<template>
  <div
    class="video-card"
    :class="{
      large: large
    }"
    @mouseover="onMouseover" @mouseleave="onMouseleave"
  >
    <div class="video-container">
      <video v-show="isShow" :src="videoUrl" ref="video" preload="auto" />
      <img v-show="!isShow" :src="videoCover" alt="">
    </div>
    <div class="video-info-container">
      <h4>{{ title }}</h4>
      <p>{{ detail }}</p>
    </div>
    <div v-show="isShow" class="video-operation-container">
      <div class="btn collect" @click.stop.prevent="handleVideoCollect(info)">
        <img v-if="collected === 1" src="~@/assets/image/campingPic/collected-active.png" alt="">
        <img v-else src="~@/assets/image/campingPic/collection@2x (1).png" alt="">
      </div>
      <div class="btn share" @click.stop.prevent="handleShare('', info.object_id, 4, info.name)">
        <img src="~@/assets/image/campingPic/share@2x (1).png" alt="">
      </div>
      <div class="btn download" @click.stop.prevent="handleDownload(info)">
        <img src="@/assets/image/campingPic/download@2x.png" alt="">
      </div>
      <div class="btn carted" @click.stop.prevent="handleVideoAddCart(info)">
        <img v-if="carted=== 1" src="@/assets/image/campingPic/@shoppx (1).png" alt="">
        <img v-else src="@/assets/image/campingPic/@shoppx.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Buttons from "@/layout/mixins/Buttons";
import { send_eventApi } from "@/utils/common";
import { shareInfoById } from "@/utils/operate";
import { copyInfo } from "@/utils/utils";

export default {
  name: "VideoCardForActive",
  props: {
    videoUrl: {
      type: String,
      default() {
        return "";
      }
    },
    title: {
      type: String,
      default() {
        return "";
      }
    },
    detail: {
      type: String,
      default() {
        return "";
      }
    },
    info: {
      type: Object,
      default() {
        return {};
      }
    },
    collected: {
      type: null,
      default() {
        return null;
      }
    },
    carted: {
      type: null,
      default() {
        return null;
      }
    },
    large: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  computed: {
    videoCover() {
      const { videoUrl, large } = this;
      const offsetSeconds = 1;
      const width = large ? 594 : 282;
      return videoUrl && videoUrl !== "" ? `${videoUrl}?vframe/jpg/offset/${offsetSeconds}/w/${width}` : "";
    }
  },
  mixins: [Buttons],
  methods: {
    onMouseover() {
      this.isShow = true;
      this.videoPlay();
    },
    onMouseleave() {
      this.isShow = false;
      this.videoReset();
    },
    videoPlay() {
      this.$refs.video.volume = 0;
      this.$refs.video.play();
    },
    /**
     * 打开分享模态框
     * 2021-12-20 15:47:38
     * @author SnowRock
     */
    async handleShare(link, id, mode, name) {
      // 分享埋点
      const obj = {
        m_type: "视频",
        m_name: name,
        mid: id,
        user_name: this.userInfo ? this.userInfo.name : "",
        user_id: this.userInfo ? this.userInfo.user_id : ""
      };
      send_eventApi("e_share", obj);
      // console.log(mode)
      name = encodeURI(name);
      let shareUrl = "";
      if (mode == 1) {
        shareUrl = `${window.location.origin}/aiVoice`;
        await shareInfoById(id, mode, shareUrl);
      } else {
        shareUrl = `${window.location.origin}/share?music_id=${id}&type=${mode}`;
        copyInfo(shareUrl);
        this.$message.success("已复制该链接到剪切板，快去分享吧！");
      }
    },
    async handleVideoCollect(info) {
      await this.handleCollect(info.object_id, 4, info, false);
      this.$emit("change", {
        ...info,
        is_collect: this.collected === 1 ? 0 : 1,
        is_collected: this.collected === 1 ? 0 : 1
      });
    },
    async handleVideoAddCart(info) {
      await this.handleAddGoodsToCart(info, 4);
      if (this.carted === 0) {
        this.$emit("change", info);
        this.$emit("update:carted", 1);
      }
    },
    videoReset() {
      this.$refs.video.currentTime = 0;
      this.$refs.video.pause();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-over-h {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.video-card {
  width: 100%;
  height: 158px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    .video-info-container {
      background: transparent !important;
    }
  }

  .video-container {
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 158px;
      border-radius: 8px;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &.large {
    height: 342px;

    .video-container {
      video {
        width: auto;
        height: 342px;
      }
    }

    .video-info-container {
      padding: 32px 24px;
    }
  }

  .video-info-container {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7000) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 16px;
    z-index: 1;

    h4 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 22px;
      margin: 0;
      margin-bottom: 8px;
      @extend .text-over-h;
    }

    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7500);
      line-height: 12px;
      margin: 0;
      @extend .text-over-h;
    }
  }

  .video-operation-container {
    position: absolute;
    bottom: 8px;
    right: 12px;
    z-index: 2;
    display: flex;

    .btn {
      width: 42px;
      height: 42px;
      background: rgba(0, 0, 0, 0.6000);
      border-radius: 4px;
      backdrop-filter: blur(1px);
      text-align: center;
      padding: 9px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }

      & + .btn {
        margin-left: 12px;
      }
    }
  }
}
</style>
