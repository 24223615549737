var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vf-sound-effect"},[_c('div',{staticClass:"vf-sound-effect-top"},[_c('div',{staticClass:"top-cover",on:{"click":function($event){return _vm.handlePlayerMusic({
      ..._vm.data,
      type: 3,
      labels: _vm.data.label
    })}}},[_c('img',{staticClass:"record",attrs:{"src":require('@/assets/image/sound-effect-record.png')}}),_c('img',{class:['player-control', { 'player-control-pause': !(_vm.isPlay && _vm.objectId == _vm.data.object_id) }],attrs:{"src":require(`@/assets/svg/sound-effect-${_vm.isPlay && _vm.objectId == _vm.data.object_id ? 'play' : 'pause'}.svg`)}})]),_c('div',[_c('p',{staticClass:"top-title"},[_c('span',{staticClass:"text-over-e"},[_vm._v(_vm._s(_vm.data.name))]),(_vm.data.is_show_hot === 1)?_c('img',{attrs:{"src":require(`@/assets/svg/${_vm.orderNo == 2 && _vm.data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)}}):_vm._e()]),_c('p',{staticClass:"top-tag text-over-e"},_vm._l((_vm.data.label.length
          ? _vm.data.label.slice(0, 2)
          : []),function(item,index){return _c('Tag',{key:index,attrs:{"plain":""}},[_vm._v(_vm._s(item.name))])}),1)])]),_c('div',{staticClass:"vf-sound-effect-bottom"},[_c('p',{staticClass:"icon"},[_c('img',{attrs:{"src":require(`@/assets/svg/${_vm.data.is_collect === 0 ? 'collect' : 'collected'}.svg`)},on:{"click":function($event){$event.stopPropagation();return _vm.handleSoundCollect(_vm.data)}}}),_c('img',{attrs:{"src":require('@/assets/svg/share-gray.svg')},on:{"click":function($event){return _vm.handleShare('', _vm.data.object_id, 3, _vm.data.name)}}}),_c('img',{attrs:{"src":require('@/assets/svg/download-gray.svg')},on:{"click":function($event){return _vm.handleDownload(_vm.data, 'single')}}})]),_c('p',{staticClass:"btn",on:{"click":function($event){return _vm.handleAddGoodsToCart({ object_id: _vm.data.object_id, type: 3, name: _vm.data.name })}}},[_vm._v(" 加入购物车 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }