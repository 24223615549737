<template>
  <div class="vf-sound-effect">
    <div class="vf-sound-effect-top">
      <div class="top-cover" @click="handlePlayerMusic({
        ...data,
        type: 3,
        labels: data.label
      })">
        <img class="record" :src="require('@/assets/image/sound-effect-record.png')" />
        <img :class="['player-control', { 'player-control-pause': !(isPlay && objectId == data.object_id) }]"
          :src="require(`@/assets/svg/sound-effect-${isPlay && objectId == data.object_id ? 'play' : 'pause'}.svg`)" />
      </div>

      <div>
        <p class="top-title">
          <span class="text-over-e">{{ data.name }}</span>
          <img v-if="data.is_show_hot === 1"
            :src="require(`@/assets/svg/${orderNo == 2 && data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)" />
          <!--          <img v-if="data.is_recommend === 1" :src="require('@/assets/svg/hot-badge.svg')" />-->
        </p>
        <p class="top-tag text-over-e">
          <Tag plain v-for="(item, index) in data.label.length
            ? data.label.slice(0, 2)
            : []" :key="index">{{ item.name }}</Tag>
        </p>
      </div>
    </div>
    <div class="vf-sound-effect-bottom">
      <p class="icon">
        <img :src="require(`@/assets/svg/${data.is_collect === 0 ? 'collect' : 'collected'}.svg`)"
          @click.stop="handleSoundCollect(data)" />
        <img :src="require('@/assets/svg/share-gray.svg')" @click="handleShare('', data.object_id, 3, data.name)" />
        <img :src="require('@/assets/svg/download-gray.svg')" @click="handleDownload(data, 'single')" />
      </p>
      <p class="btn" @click="handleAddGoodsToCart({ object_id: data.object_id, type: 3, name: data.name })">
        加入购物车
      </p>
    </div>
  </div>
</template>

<script>
import Tag from './Tag'
import Buttons from '@/layout/mixins/Buttons'
import { getToken } from "@/utils/author";

export default {
  name: 'SoundEffectCard',
  components: { Tag },
  props: {
    data: Object,
    orderNo: {
      type: Number
    }
  },
  computed: {
    musicInfo() {
      return this.$store.state.music.tmpMusicInfo
    },
    isPlay() {
      return this.$store.state.music.isPlaying;
    },
    objectId() {
      return this.$store.state.music?.tmpMusicInfo?.object_id || null
    },
  },
  watch: {
    '$store.state.music.tmpMusicInfo'(val) {
      const { is_collected } = val || {}
      if (val) {
        const { data } = this
        data.is_collect = is_collected
        this.$emit('update:data', data)
      }
    },
  },
  data() {
    return {
      // isPlay: false
    }
  },
  mixins: [Buttons],
  methods: {
    async handleSoundCollect(item) {
      if (this.musicInfo && this.musicInfo.object_id) {
        if (item.object_id === this.musicInfo.object_id) {
          this.$message.warning('正在播放的音效请在播放器中操作')
          return
        }
      }
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 3, {
        ...item,
        type: 3,
        labels: item.label
      }, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.vf-sound-effect {
  @include size(100%, 147px);
  background: $base-color;
  border-radius: 12px;
  border: 1px solid #979797;
  padding: 16px 17px 11px;
  cursor: pointer;

  &-top {
    @include center-middle-flex($justify: flex-start);
    border-bottom: 2px solid #f5f6fa;
    padding-bottom: 14px;

    .top-cover {
      position: relative;
      margin-right: 12px;
      @include square(64px);

      .record {
        @include square(100%);
      }

      .player-control {
        @include square(20px);
        @include center-middle-position;
      }

      .player-control-pause {
        left: 52%;
      }
    }

    .top-title {
      @include font-bold($size: 16px, $color: rgba(0, 0, 0, 0.8));
      @include center-middle-flex($justify: flex-start);
      line-height: 25px;
      margin-bottom: 8px;

      // span {
      //   @include text-overflow(104px);
      // }

      img {
        @include size(28px, 20px);
        margin-top: -12px;
        margin-left: 8px;
      }
    }

    .top-tag {
      .vf-tag-block {
        margin-right: 4px;
      }

      .vf-tag-block:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
  }

  &-bottom {
    @include center-middle-flex($justify: space-between);
    margin-top: 12px;

    .icon {
      width: 118px;
      @include center-middle-flex($justify: space-between);
      margin-left: 14px;

      img {
        @include square(25px);
      }
    }

    .btn {
      @include font-normal($size: 12px, $color: $theme-color);
      border-radius: 2px;
      border: 1px solid $theme-color;
      padding: 0 6px;
      height: 26px;
      line-height: 26px;
    }
  }
}
</style>
